import React from 'react';

import {
  Row,
  Col,
  Container }
from 'reactstrap';

export default function Footer() {
  return (
    <div className='bg-dark'>
      <Container>
        <Row className='py-4 text-white'>
          <Col sm='2' xs='4'>
            <a rel="license" href="http://creativecommons.org/licenses/by-nc-sa/4.0/"><img alt="Creative Commons License" src="../../cc_license.png" /></a>
          </Col>
          <Col sm='10' xs='8'>
            <Col>© Copyright 2020 |   Created by <a href='https://wantinghsieh.com/'>Wan-Ting Hsieh</a>   |   All Rights Reserved </Col>
            <Col>This work is licensed under a <a rel="license" href="http://creativecommons.org/licenses/by-nc-sa/4.0/">Creative Commons Attribution-NonCommercial-ShareAlike 4.0 International License</a>.</Col>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
