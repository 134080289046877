import React from 'react';
import JSONdatas from '../data/assignments.json';
import { Link, StaticQuery, graphql } from 'gatsby';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText
} from 'reactstrap';


export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navbarToggle: false,
    };
    this.handleNavbarToggle = this.handleNavbarToggle.bind(this);
  }

  render() {
    return (
      <div id="top">
        <div className='bg-faded'>
          <div className='shadow-lg mb-2 rounded'>

              <StaticQuery
                query={graphql`
                  query {
                    site {
                      siteMetadata {
                        title
                        author
                      }
                    }
                    allMarkdownRemark (
                      sort: {
                        order: ASC,
                        fields: frontmatter___weekId
                      }
                    ) {
                      edges {
                        node {
                          frontmatter {
                            visibility
                            weekId
                            title
                          }
                          fields {
                            slug
                          }
                        }
                      }
                    }
                  }
                `}
                render={ data =>
                  <Navbar color='dark' dark expand='lg'>
                    <NavbarBrand className='text-warning' tag={Link} to='/'>
                      {data.site.siteMetadata.title}
                    </NavbarBrand>
                    <NavbarToggler onClick={this.handleNavbarToggle} />
                    <Collapse isOpen={this.state.navbarToggle} navbar>
                      <Nav className='mx-auto' navbar>
                        <NavItem className='mx-lg-3'>
                          <NavLink tag={Link} to='/'>Home</NavLink>
                        </NavItem>
                        <NavItem className='mx-lg-3'>
                          <NavLink tag={Link} to='/about'>About</NavLink>
                        </NavItem>

                        <UncontrolledDropdown nav inNavbar className='mx-lg-3'>
                          <DropdownToggle nav caret>
                            Assignments
                          </DropdownToggle>
                          <DropdownMenu right>
                            {data.allMarkdownRemark.edges.map(edge => (
                              <DropdownItem className={edge.node.frontmatter.visibility ? '' : 'd-none'} key={edge.node.frontmatter.weekId} tag={Link} to={`/assignment/${edge.node.fields.slug}`}>
                                Week {edge.node.frontmatter.weekId}: {edge.node.frontmatter.title}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </UncontrolledDropdown>

                        <NavItem className='mx-lg-3'>
                          <NavLink tag={Link} to='/assignment/final'>Final Project</NavLink>
                        </NavItem>
                        <NavItem className='mx-lg-3'>
                          <NavLink href='https://gitlab.com/cv47522/fab-academy'>
                          <i className="fa fa-gitlab" aria-hidden="true"></i>  GitLab</NavLink>
                        </NavItem>
                      </Nav>

                      <NavbarText>
                        <a className='text-decoration-none text-secondary' target='_blank'
                          href='http://wantinghsieh.com'>
                          {data.site.siteMetadata.author}
                        </a>
                      </NavbarText>
                    </Collapse>
                  </Navbar>
                }/>
          </div>
        </div>
      </div>
    );
  }

  handleNavbarToggle() {
    this.setState((prevState, props) => ({
      navbarToggle: !prevState.navbarToggle,
    }));
  }
}
